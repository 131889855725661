var Otto = Otto || {};

/**
 * Manage our header/sidebar module
 */
Otto.header = (function(my, $) {
    var exports = {},
        toggleRef           = $('#header-toggle'),
        headerRef           = $('#header'),
        contentRef          = $('#content'),
        isOpenClass         = 'is-open-header',
        toggleCloseClass    = 'header-toggle--state-close';

    function toggle() {
        if (toggleRef.hasClass(isOpenClass)) {
            // menu is currently OPEN - let's close it
            closeMenu();
        } else {
            // menu is currently CLOSED - let's open it
            openMenu();
        }
    }

    function openMenu() {
        toggleRef.addClass(isOpenClass + ' ' + toggleCloseClass);
        headerRef.addClass(isOpenClass);
        contentRef.addClass(isOpenClass);
    }

    function closeMenu() {
        toggleRef.removeClass(isOpenClass + ' ' + toggleCloseClass);
        headerRef.removeClass(isOpenClass);
        contentRef.removeClass(isOpenClass);
    }

    function wireEvents() {
        toggleRef.click(function(evt){
            evt.preventDefault();
            toggle();
        });
        // Let's also set up our Callout event tracking here.  Note that
        // we need to trigger the event notification BEFORE we actually
        // navigate away from the page.
        $('.hiring__link').click(function(evt){
            evt.preventDefault();
            var targetUrl = $(this).attr('href');
            ga('send', 'event', 'Call to Action', 'Click', 'Now Hiring');
            setTimeout(function(){
                window.location.href = targetUrl;
            },100);
        });
        // Let's listen for changes in footer visibility as well.
        $('body').on('footerVisChange', onFooterVisChange);
    }

    function onFooterVisChange(evt) {
        if (evt.isFooterVisible) {
            // The full footer is visible, so let's hide our side bar 'footer' elements
            hideSidebarFooter();
        } else {
            // The full footer is NOT visible - make sure the side bar footer is
            showSidebarFooter();
        }
    }

    function hideSidebarFooter() {
        $('.hiring').addClass('off-screen');
        headerRef.find('.aux-items').addClass('off-screen');
    }
    function showSidebarFooter() {
        $('.hiring').removeClass('off-screen');
        headerRef.find('.aux-items').removeClass('off-screen');
    }

    function initHiringCallout() {
        $(window).resize(function(evt){
            setHiringPosition();
        });
        setHiringPosition();
    }

    function setHiringPosition() {
        // NOTE: these values pulled from _page.scss -- if they change
        // there, this script should be updated as well.
        var mobileLeft = '15px',
            mobileBottom = '20px',
            tabletLeft = '50px',
            tabletBottom = '116px',
            tabletShortRight = '20px',
            tabletShortBottom = '20px';

        var $callout = $('.hiring'),
            winH = $(window).height(),
            winW = $(window).width(),
            isMobile = (winW < 768),
            isShort = (winH < 730);

        if (!isMobile && isShort) {
            $callout.css({
                        'right': tabletShortRight,
                        'left': 'auto',
                        'bottom': tabletShortBottom
                        });
        } else if (!isMobile && !isShort) {
            $callout.css({
                        'right': 'auto',
                        'left': tabletLeft,
                        'bottom': tabletBottom
                        });
        } else if (isMobile) {
            $callout.css({
                        'right': 'auto',
                        'left': mobileLeft,
                        'bottom': mobileBottom
                        });
        }
    }

    function hideScroller() {
        setTimeout(function () {
            headerRef.find('.header__inner-wrap').getNiceScroll().hide();
        }, 100);
    }

    exports.init = function() {
        wireEvents();

        // Initialize scrolling
        headerRef.find('.header__inner-wrap').niceScroll(
                            {railpadding:{top:1,right:2,left:2,bottom:1},
                             cursorborder:"0",
                             cursorcolor:"#333333"});

        // Remove our helper class for header vis.  We need to always
        // initially hide the header from mobile devices, as on the
        // interior pages, we always have it open for desktop.  If
        // we ARE on mobile, when removing the vis helper, close the
        // header per standard method.
        //
        // NOTE: Classes are added on page load of the home page
        // (see template file) in order to manage menu vis there,
        // which is different than other pages.

        if (my.util.isMobile()) {
            closeMenu();
        }
        headerRef.removeClass('is-mobile-hidden');

        // Set up our hiring callout
        initHiringCallout();

        // Initially hide the scrollbar to avoid it showing when not needed
        hideScroller();
    };
    exports.openMenu = function() {
        openMenu();
    };
    exports.closeMenu = function() {
        closeMenu();
    };

    return exports;
})(Otto, jQuery);
